<template>
  <div class="newsContent">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">消息详情</div>
    <div class="card">
            <div class="newtitle">{{detail.title}}</div>
            <div class="newtime flex">
                <img src="@/assets/img/time.png">
                <span style="margin-right: 4vw">{{detail.createTime}}</span>                 
            </div>
            <div class="card-html" v-html="replacePath(detail.content)"></div>
            <p v-if="detail.fileName" class="accessory">附件：<a :href="store.state.imgURL2+ detail.fileName" download>{{detail.fileName.substr(detail.fileName.lastIndexOf("/") + 1)}}</a></p>
    </div>
   
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { apiGetPushInfoDetail,apiUpdateReadStatus } from '@/api/axios';
import { Toast } from 'vant';
import { ref} from 'vue';
import { useStore } from 'vuex';
export default {
   setup() {
       let router = useRouter();
       let store = useStore();
       let id = router.currentRoute.value.params.id;
       const detail = ref({});
       apiGetPushInfoDetail({id: id}).then(res => {                  
               if(res.code == '1'){
                   detail.value = res.info.pushInfoDetail;
                   setLocal(res.info.pushInfoDetail.type);
               }
               else {
                   Toast(res.msg);
               }
       });
       
        const setLocal = (type) =>{
            let loacalMessage = JSON.parse(localStorage.getItem("gxrMessage"));
            if(type=='1' && detail.value.readStatus == '0') {
                loacalMessage.loanList.forEach((item) => {
                    if(item.id == id) {
                        item.readStatus = '1'
                    }
                });
                apiUpdateReadStatus({ id: id,type: '1',readStatus: '1'}).then(res => {
                    if(res.code == '1'){
                        console.log('更新阅读状态成功');
                    }
                    else {
                        Toast(res.msg);
                    }
                });
            }
            if(type=='2') {
                loacalMessage.productRecomList.forEach((item) => {
                    if(item.id == id) {
                        item.readStatus = '1'
                    }
                })
            }
            if(type=='3') {
                loacalMessage.policyList.forEach((item) => {
                    if(item.id == id) {
                        item.readStatus = '1'
                    }
                })
            }
            localStorage.setItem("gxrMessage", JSON.stringify(loacalMessage));
            store.dispatch('setMsg',loacalMessage);
        }

        const replacePath = (str) => {
            let reg = new RegExp( '/gxfinan/' , "g" )
            let newstr=str.replace(reg,store.state.imgURL2);
            return newstr;
        }

        
    return {
        store,
        detail,
        replacePath
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.newsContent {
    padding-top: 12.5vw;
    .card {
        overflow: hidden;
        .newtitle {
            font-size: 5vw;
            font-weight: 500;
        }
        .newtime {
            font-size: 3.3vw;
            color: #ccc;
            padding: 4vw 0;
            img {
            width: 3.5vw;
            height: auto;
            margin-right: 1vw;
            }
        }
        .newtext {
            font-size: 4vw;
            margin: 4vw 0;
            line-height: 7vw;
        }
        img {
            width: 100%;
            height: auto;
        }
        .accessory {
          font-size: 4vw;
          word-break: break-all;
          span {
            color: rgb(19, 100, 250);
          }
        }
    }
}
</style>
